import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { AlertController, Platform } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { OrderImage } from '../../models/order-image';
import { ImagePickerEvent } from '../../models/ImagePickerEvent';


@Component({
  selector   : 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls  : ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit {

  @Output() valueChange: EventEmitter<ImagePickerEvent>
                                               = new EventEmitter<ImagePickerEvent>();
  @Output() imageRemoved: EventEmitter<number> = new EventEmitter<number>();
  @Output() removeAllImages: EventEmitter<any> = new EventEmitter<any>();
  @Output() useDifferentPictureForEachCalendarChecked: EventEmitter<any> = new EventEmitter<any>();

  @Input() images: OrderImage[];
  @Input() loadingArray: number[];
  @Input() maxNumberOfPhotos: number;
  @Input() numberOfImagesFailed: number;
  @Input() allowMultiselect: boolean;
  @Input() isCalendarInput: boolean;
  @Input() separateImagePerCalendar: boolean;

  public files                   = [];
  public baseUrl                 = environment.storageUrl;
  public isApp                   = this.plt.is('capacitor') && !this.plt.is('mobileweb');
  public numberOfAlreadyUploaded = 0;
  public months                  = [
    'Januar',
    'Februar',
    'Mart',
    'April',
    'Maj',
    'Jun',
    'Jul',
    'Avgust',
    'Septembar',
    'Oktobar',
    'Novembar',
    'Decembar',
  ];

  constructor(
    private imagePicker: ImagePicker,
    public plt: Platform,
    public alertController: AlertController
  ) {
  }

  ngOnInit() {
    if (!this.numberOfAlreadyUploaded) {
      this.numberOfAlreadyUploaded = 0;
    }
  }

  emitChanges(index) {
    this.valueChange.emit({
      files    : this.files,
      index,
      fromPhone: this.isApp
    });
  }

  async addImage(index = -1) {
    const options = {
      maximumImagesCount: 999,
      quality           : 100,
      outputType        : 1 // base64
    };

    if (this.maxNumberOfPhotos !== -1) {
      options.maximumImagesCount = this.maxNumberOfPhotos || 1;
    }
    const images = await this.imagePicker.getPictures(options);
    images.map(image => {
      const blobData = this.b64toBlob(image, `image/jpg`);
      this.files.push(blobData);
    });
    this.emitChanges(index);
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays     = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  getImageFromDesktop(event, index = -1) {
    this.numberOfAlreadyUploaded = this.images?.length || 0;
    const {files}                = event.target;
    this.files                   = files;
    this.emitChanges(index);
  }

  calculateUploadProgress() {
    return `${this.calculateImagesToUpload()} / ${this.files?.length}`;
  }

  calculateImagesToUpload() {
    const imagesToUpload = (+this.images?.length) - (+this.numberOfAlreadyUploaded) + (+this.numberOfImagesFailed);
    if (typeof imagesToUpload === 'number') {
      return imagesToUpload;
    }
    return 0;
  }

  findIMageForMonth(monthIndex): OrderImage {
    return this.images.find(img => img.month === (monthIndex));
  }

  removeImage(index) {
    this.imageRemoved.emit(index);
  }

  separateImageCheckChange(event) {
    this.useDifferentPictureForEachCalendarChecked.emit(event);
  }

  async removeAllUploadedImages() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header  : 'Ukloni Sve slike',
      message : 'Da li ste sigurni da želite ukloniti sve izabrane slike?',
      buttons : [
        {
          text    : 'Odustani',
          role    : 'cancel',
          cssClass: 'secondary',
          handler : () => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text   : 'Ukloni',
          role   : 'destructive',
          handler: () => {
            this.removeAllImages.emit();
          }
        }
      ]
    });

    await alert.present();
  }
}
